import React from 'react'
import WitWrapper from '../components/wit/witWrapper'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { Seo } from '../components/seo/Seo'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const Wit: React.FC = () => {
  const currentSlug = '/en/interest-test'
  const { collectBreadCrumbs } = useNavigationItems()
  const seoTitle = 'Interest test'
  const seoDescription = 'The interest test of hoch und weit'
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={GenerateLocales.en} description={seoDescription}></Seo>
      <LayoutWrapper
        currentSlug={currentSlug}
        currentTitle="Areas of interest"
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.de, slug: '/weiterbildungs-interessentest/' }]}
      >
        <WitWrapper />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/interest-test/`} />
}

export default Wit
